@import '../_vars';
.participations{
  margin: 1rem auto;
  .MuiTypography-h6{
    font-style: italic;
    line-height: 1;
    font-size: 1rem;
    font-family: arial, sans-serif;
    opacity: 0.6;
    transform: translateY(2px);
  }
  .secondary-info{
    font-style: italic;
    opacity: 0.4;
  }
  .MuiTableContainer-root{
    background: rgba(228,108,10,0.1);
  }
  .MuiTypography-h6{
    color: $color_primary;
  }
}