@import '../_vars';
.maintenant{
  .now-status{
    .sublabel{
      font-size: 0.8rem;
      line-height: 1;
    }
    .label{
      font-size: 2rem;
      line-height: 1;
    }
    .people{
      font-size: 0.9rem;
      margin: 0.5rem auto;
    }
    

    // success si ouvert + permanences>=2
    // warn si ouvert + permanence=1
    // danger si ouvert + !permanences
    // disabled si !ouvert
    &.success{
      .sublabel,
      .label,
      .people{
        color: $color_success;
      }
    }
    &.warn{
      .sublabel,
      .label,
      .people{
        color: $color_warn;
      }
    }
    &.danger{
      .sublabel,
      .label,
      .people{
        color: $color_danger;
      }
    }
    &.disabled{
      .sublabel,
      .label,
      .people{
        color: $color_disabled;
      }
    }
  }
}