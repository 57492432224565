@import '../_vars';
.keyNumber{
  width: 70%;
  display: inline-block;
  .keyNumber-circle{
    border-radius: 50%;
    width: auto;
    height: auto;
    padding-bottom: 100%;
    position: relative;
    background: $color_lightgrey;
  }
  .keyNumber-texts{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-60%);
  }
  .keyNumber-sub{
    opacity: 0.6;
    line-height: 1;
    font-style: italic;
    margin-bottom: 0.5rem;
  }
  .keyNumber-label{
    opacity: 0.6;
    line-height: 1;
  }
  .keyNumber-value{
    font-size: 2rem;
    font-weight: bold;
    font-family: arial, sans-serif;
  }
  // variants color
  &.danger{   // solde <= 10€ ou <= 1h
    color: #fff;
    .keyNumber-circle{
      background: $color_danger;
    }
  }
  &.warn{   // solde <= 50€ ou <= 3h 
    color: #fff;
    .keyNumber-circle{
      background: $color_warn;
    }
  }
  &.success{   // solde > 50€ ou  > 3h
    color: #fff;
    .keyNumber-circle{
      background: $color_success;
    }
  }
}

