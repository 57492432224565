@import '../_vars';
.horaires{
  .MuiTypography-h6{
    font-style: italic;
    line-height: 1;
    font-size: 1rem;
    font-family: arial, sans-serif;
    opacity: 0.4;
    transform: translateY(2px);
  }
  .day-closed{
    font-style: italic;
    opacity: 0.4;
  }
  .current-day{
    .MuiTableCell-root{
      font-weight: bold;
    }
  }
}

