@import '../_vars';
.commandes{
  margin: 1rem auto;
  .MuiTypography-h6{
    font-style: italic;
    line-height: 1;
    font-size: 1rem;
    font-family: arial, sans-serif;
    opacity: 0.6;
    transform: translateY(2px);
  }
  .MuiTableContainer-root{
    background: rgba(159,198,26,0.1);
  }
  .MuiTypography-h6{
    color: $color_secondary;
  }
}

.MuiDialog-container{
  .table-title{
    font-style: italic;
    line-height: 1;
    font-size: 1rem;
    font-family: arial, sans-serif;
    opacity: 0.6;
    transform: translateY(2px);
  }
  .MuiTable-root *{
    font-size: 0.700rem !important;
    line-height: 1;
  }
  .disabled{
    opacity: 0.6;
    font-style: italic;
    text-decoration:line-through;
  }
  .legend{
    font-size: 0.9rem;
    opacity: 0.8;
  }
}