@import './_vars';
.App {
  text-align: center;

  // commentaires pour présenter la PWA
  .info{
    font-style: italic;
    opacity: 0.7;
    text-align: left;
    font-size: 0.8rem;
  }

  // general -----------------
  .MuiTypography-h5{
    margin-bottom: 1rem;
  }
  .MuiButton-containedPrimary{
    background-color: $color_primary !important;
  }
  .MuiButton-containedSecondary{
    background-color: $color_secondary !important;
  }
  .grid{
    margin-top: 1rem;
  }
  .MuiTableCell-head{
    font-weight: bold;
    line-height: 1.2;
  }

  // separateur
  .separator{
    width: 60%;
    border-bottom: 1px solid $color_lightgrey;
    margin: 1rem auto;
  }

  // live
  .live{
    padding: 1rem;
  }

  // moncompte
  .moncompte{
    padding: 1rem;
  }


  // news
  .news{
    padding: 1rem;
  }


  // planning
  .planning{
    padding: 1rem;
  }

  // l'epi
  .lepi{
    padding: 1rem;
    img{
      display: block;
      margin: 0 auto;
      max-width: 20%;
    }
  }

  .loading{
    position: fixed;
    top: 1rem;
    right: 1rem;
  }

  .footer{
    background: rgba(145,178,187,0.1);
    padding: 0.5rem;
    font-size: 0.8rem;
    *{
      color: $color_text !important;
    }
  }

}

.MuiDialog-paperFullScreen {
  width: 90% !important;
  height: auto !important;
  margin: 0 auto !important;
  max-width: 90% !important;
  max-height: none;
  border-radius: 4px !important;
  .dialog-wrapper{
    padding: 1rem;
  }
  .MuiDialogTitle-root{
    text-align: left;
    .MuiTypography-h6{
      text-align: left;
    }
  }
}
.MuiSvgIcon-root{
  vertical-align: middle;
}