@import '../_vars';
.ardoise{
  .panel{
    background: #354151;
    color: #f1f1f1;
    border: 5px solid #A47561;
    padding: 0.5rem;
    text-align: left;
  }
  .update{
    text-align: right;
    opacity: 0.8;
    font-style: italic;
    font-size: 0.8rem;
    line-height: 1;
    color: #A47561;
  }
}