$color_lightgrey: #D9D9D9;
$color_background: linear-gradient(#D9D9D9 0%, #ffffff 100%);
$color_text: rgb(74,74,74); // #4a4a4a
$color_primary: rgb(228,108,10); // orange epi
$color_secondary: rgb(159,198,26);  // vert epi #77c61a
$color_third: rgb(145,178,187); // bleu epi
$color_danger: rgb(229, 50, 50); //rgb(237, 91, 54);
$color_warn: rgb(237, 176, 54);
$color_success: rgb(86, 193, 29); 
$color_info: rgb(54, 200, 237);
$color_disabled: rgba(0, 0, 0, 0.6);