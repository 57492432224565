@import '../_vars';
.aujourdhui{
  margin: 1rem auto;
  .MuiTableContainer-root{
    background: rgba(217, 217, 217, 0.3);
  }
  .MuiTypography-h6{
    font-style: italic;
    line-height: 1;
    font-size: 1rem;
    font-family: arial, sans-serif;
    opacity: 0.4;
    transform: translateY(2px);
  }
  .status{
    &.success{
      color: $color_success;
    }
    &.warn{
      color: $color_warn;
    }
    &.danger{
      color: $color_danger;
    }
    &.disabled{
      color: $color_disabled;
    }
  }
  .current-hour{
    font-weight: bold;
    .MuiTableCell-root{
      font-weight: bold;
    }
  }
  .pasted-hour{
    font-style: italic;
    opacity: 0.7;
    .MuiTableCell-root{
      font-style: italic;
      opacity: 0.7;
    }
  }
  .secondary-info{
    font-style: italic;
    opacity: 0.4;
  }
  .epicerie{
    background: rgba(145,178,187,0.1);
  }
}